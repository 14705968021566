import React, {useState, useEffect} from 'react';
import MDEditor from "@uiw/react-md-editor";
import { useOutletContext } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function DashboardProfile()
{
  // State variables
  const [state, setState] = useState({
    avatar: '',
    name: '',
    title: '',
    occupation: '',
    location: '',
    description: '',
    email: '',
    github: '',
    linkedIn: '',
    successMsg: '',
    errorMsg: '',
    imageSuccessMsg: '',
    imageErrorMsg: '',
  });


  const [authenticated, setAuthenticated] = useOutletContext();


  useEffect(function ()
  {
    const fetchProfile = async function ()
    {
      try
      {
        // Fetch data
        const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();

        if (!res.ok)
        {
          console.log('Error trying to fetch profile');
          console.log(data);
          return ;
        }
        else if (res.ok)
        {
          console.log('Received:');
          console.log(data);
          setState({...state, avatar: data.avatar, name: data.name, title: data.title, occupation: data.occupation, location: data.location, description: data.description, email: data.email, github: data.github, linkedIn: data.linkedIn});
          return ;
        }
      }
      catch(error)
      {
        console.log('Caught error trying to fetch Profile');
        console.log(error);
      }
    }

    fetchProfile();

  }, []);

  const submitAvatar = async function (context)
  {
    try
    {
      context.preventDefault();
      console.log('Form context.target[0].files[0]');
      console.log(context.target[0].files[0]);
      if (typeof(context.target[0].files[0]) === 'undefined')
      {
        setState({...state, successMsg: '', errorMsg: 'Please select a file'})
        return ;
      }
      let formData = new FormData();
      formData.append('avatar', context.target[0].files[0], context.target[0].files[0].name);
      console.log(formData);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/update/changeavatar`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
        },
        body: formData
      });
      const data = await res.json();
      if (!res.ok)
      {
        if (res.status === 401)
        {
          setAuthenticated(false);
          setTimeout(function () {
            submitAvatar(context);
            }, 2000);
          return ;
        }
        else
        {
        setState({...state, imageErrorMsg: `Error: ${data.message}`, imageSuccessMsg: ''});
        return ;
        }
      }
      else
      {
        setState({...state, imageErrorMsg: '', imageSuccessMsg: `Success: avatar updated`, avatar: data.avatar});
      }
    }
    catch(e)
    {
      console.log('Caught error trying to submitAvatar');
      console.log(e);
    }
  }

  const submitProfile = async function (context)
  {
    context.preventDefault();
    try
    {
      
      let profileDto = {
        name: `${state.name}`,
        title: `${state.title}`,
        occupation: `${state.occupation}`,
        location: `${state.location}`,
        description: `${state.description}`,
        email: `${state.email}`,
        github: `${state.github}`,
        linkedIn: `${state.linkedIn}`,
      };
      console.log(profileDto);
      let res = await fetch(`${process.env.REACT_APP_API_URL}/update/profile`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileDto),
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          setAuthenticated(false);
          setTimeout(function () {
            submitProfile(context);
            }, 2000);
          return ;
        }
        else
        {
          const data = await res.json();
          setState({...state, errorMsg: `Error: ${data.message}`, successMsg: ''});
          return ;
        }
      }
      else
      {
        setState({...state, errorMsg: '', successMsg: `Success: Profile updated`});
      }
    }
    catch(e)
    {
      console.log('Caught error trying to submitProfile');
      console.log(e);
    }
  }

  const removeAvatar = async function (context)
  {
    context.preventDefault();
    console.log('Attempting to remove avatar');
    const res = await fetch(`${process.env.REACT_APP_API_URL}/update/removeavatar`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
      },
    });
    const data = await res.json();
    if (!res.ok)
    {
      if (res.status === 401)
      {
        setAuthenticated(false);
        setTimeout(function () {
          removeAvatar(context);
          }, 2000);
        return ;
      }
      else
      {
        setState({...state, imageErrorMsg: `Error: ${data.message}`, imageSuccessMsg: ''});
        return ;
      }
    }
    else
    {
      setState({...state, imageErrorMsg: '', imageSuccessMsg: `Success: avatar removed`, avatar: ''});
    }
  }


  return (
      <Container className='bg-white'>
        <Row className='py-5'>
          <Col className='mx-auto text-center'>
            <p className='text-success'>{state.imageSuccessMsg}</p>
            <p className='text-danger'>{state.imageErrorMsg}</p>
            <Image width='100px' height='100px' className='my-1' roundedCircle={true} src={ state.avatar} />
            <Form onSubmit={submitAvatar}>
              <Form.Group controlId="formFileLg" className="mx-1 my-1">
                <Form.Control className='mx-1 my-1' type="file" size="lg" name="avatar" />
                <Button variant="secondary" type="submit" className='mx-1 my-2'>Change Avatar</Button>
              </Form.Group>
            </Form>
            <Button onClick={removeAvatar} variant="danger" type="submit" className='mx-1 my-1'>Remove Avatar</Button>
            
            <Form onSubmit={submitProfile}>
              <Row className='mt-5'>
              <p className='text-success'>{state.successMsg}</p>
              <p className='text-danger'>{state.errorMsg}</p>
                <Form.Label column lg={2}>
                  Full Name
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Full Name" value={state.name ? state.name : ''} onChange={(e) => { setState({...state, name: e.target.value}) }} />
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Title
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Title" value={state.title ? state.title : ''} onChange={(e) => { setState({...state, title: e.target.value}) }}/>
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Occupation
                </Form.Label>
                <Col>
                <Form.Control style={{display: 'inline-table'}} as={MDEditor} value={state.occupation ? state.occupation : ''} onChange={(e) => { setState({...state, occupation: e}) }}/>
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Location
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Location" value={state.location ? state.location : ''} onChange={(e) => { setState({...state, location: e.target.value}) }}/>
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Description
                </Form.Label>
                <Col>
                <Form.Control style={{display: 'inline-table'}} as={MDEditor} height='500px' value={state.description ? state.description : ''} onChange={(e) => { setState({...state, description: e}) }}/>
                </Col>
              </Row>

              <h2 className='mt-5'>Contact</h2>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Email
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Email address" value={state.email ? state.email : ''} onChange={(e) => { setState({...state, email: e.target.value}) }}/>
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  Github
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Github url" value={state.github ? state.github : ''} onChange={(e) => { setState({...state, github: e.target.value}) }}/>
                </Col>
              </Row>

              <Row className='mt-1'>
                <Form.Label column lg={2}>
                  LinkedIn
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="LinkedIn url" value={state.linkedIn ? state.linkedIn : ''} onChange={(e) => { setState({...state, linkedIn: e.target.value}) }}/>
                </Col>
              </Row>

              <Button type='submit' className='mx-1 my-3'>Update Changes</Button>
            </Form>

          </Col>
        </Row>
      </Container>
  );

  
}

export default DashboardProfile;
