import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Stack from "react-bootstrap/Stack";

import { Link } from "react-router-dom";

import Footer from "./Footer.tsx";
import ToolPill from "./ToolPill.tsx";
import ProjectCard from "./ProjectCard.tsx";

import Markdown from "markdown-to-jsx";
import { darkmodeState } from "../index.tsx";
import { useRecoilState } from "recoil";

function Profile() {
  // State variables
  const [state, setState] = useState({
    avatar: "",
    name: "",
    title: "",
    occupation: "",
    location: "",
    description: "",
    email: "",
    github: "",
    linkedIn: "",
    darkmode: false,
  });
  const [darkmode, setDarkmode] = useRecoilState(darkmodeState);

  // fetch data logic from home module of server
  useEffect(function () {
    const fetchProfile = async function () {
      try {
        // Fetch data
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/resource/profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await res.json();

        if (!res.ok) {
          console.log("Error trying to fetch profile");
          console.log(data);
          return;
        } else if (res.ok) {
          console.log("Received:");
          console.log(data);
          setState({
            ...state,
            avatar: data.avatar,
            name: data.name,
            title: data.title,
            occupation: data.occupation,
            location: data.location,
            description: data.description,
            email: data.email,
            github: data.github,
            linkedIn: data.linkedIn,
          });
          return;
        }
      } catch (error) {
        console.log("Caught error trying to fetch Profile");
        console.log(error);
      }
    };

    fetchProfile();
  }, []);

  const headshotUrl: string = `${process.env.REACT_APP_API_URL}/srcs/users/headshot.jpg`;
  const gemIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/gem.svg`;
  const briefcaseIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/briefcase.svg`;
  const gradIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/graduation-cap.svg`;
  const locationIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/location-dot.svg`;
  const mailIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/mail.svg`;
  const githubIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/github.svg`;
  const linkedInIcon: string = `${process.env.REACT_APP_API_URL}/srcs/icons/linkedin.svg`;
  const emailAddress: string = `mailto:${state.email ? state.email : ""}`;

  //<div><Image src={briefcaseIcon} width='20px' height='15px' style={{fill: darkmode?'#F1F1F1' : '#010101'}} />   <Markdown>{state.occupation ? state.occupation : 'Loading...'}</Markdown></div>

  return (
    <div
      className={darkmode ? "text-light" : "text-dark"}
      style={{ backgroundColor: darkmode ? "black" : "white" }}
    >
      <header
        className={darkmode ? "py-5 general-header" : "py-5 general-header"}
        style={{ backgroundColor: darkmode ? "rgb(32 32 32)" : "#f8f9fa" }}
      >
        <div className="headshot-container">
          <Image
            width="100px"
            height="100px"
            roundedCircle={true}
            src={headshotUrl}
          ></Image>
        </div>
        <h3
          className={
            darkmode
              ? "mt-3 text-center text-light"
              : "mt-3 text-center text-dark"
          }
        >
          {state.name ? state.name : "Loading..."}
        </h3>
        <h5
          className={
            darkmode ? "text-center text-light" : "text-center text-dark"
          }
        >
          {state.title ? state.title : "Loading..."}
        </h5>
        <div
          className={
            darkmode ? "mt-3 mx-auto text-light" : "mt-3 mx-auto text-dark"
          }
          style={{ maxWidth: "450px" }}
        >
          <div>
            <Image src={gradIcon} width="20px" height="15px" />{" "}
            <a href="https://www.codam.nl/">{"Codam"}</a>
            {" Alumni"}
          </div>
          <div>
            <Image src={locationIcon} width="20px" height="15px" />{" "}
            {state.location ? state.location : "Loading..."}
          </div>
        </div>
      </header>
      <Container className="my-5">
        <Row>
          <Col className="text-center">
            <div className="content-title">Hello world</div>
            <h5>
              <Markdown>
                {state.description ? state.description : "Loading..."}
              </Markdown>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col className="my-5">
            <Nav className="flex-row justify-content-center">
              <Nav.Link
                href={emailAddress}
                target="_blank"
                eventKey="email"
                className={darkmode ? "text-light" : ""}
              >
                {" "}
                <Image src={mailIcon} height="18px" /> Email
              </Nav.Link>
              <Nav.Link
                href={state.github}
                target="_blank"
                eventKey="github"
                className={darkmode ? "text-light" : ""}
              >
                <Image src={githubIcon} height="18px" /> Github
              </Nav.Link>
              <Nav.Link
                href={state.linkedIn}
                target="_blank"
                eventKey="linkedin"
                className={darkmode ? "text-light" : ""}
              >
                <Image src={linkedInIcon} height="18px" /> LinkedIn
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={6} className="my-5">
            <h3 className="mb-3">{"Front-end frameworks"}</h3>
            <ToolPill tool="react-native" />
            <ToolPill tool="react-js" />
            <ToolPill tool="swing" />
          </Col>
          <Col md={6} className="my-5">
            <h3 className="mb-3">{"Back-end frameworks"}</h3>
            <ToolPill tool="nest-js" />
            <ToolPill tool="django" />
          </Col>
          <Col md={6} className="my-5">
            <h3 className="mb-3">{"Programming languages"}</h3>
            <ToolPill tool="javascript" />
            <ToolPill tool="python" />
            <ToolPill tool="kotlin" />
            <ToolPill tool="c" />
            <ToolPill tool="cpp" />
            <ToolPill tool="java" />
          </Col>
          <Col md={6} className="my-5">
            <h3 className="mb-3">{"Other tools"}</h3>
            <ToolPill tool="docker" />
            <ToolPill tool="typescript" />
            <ToolPill tool="postgresql" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Profile;
