import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Footer from "./Footer.tsx";
import ProjectCard from "./ProjectCard.tsx";
import ToolPill from "./ToolPill.tsx";

import { darkmodeState } from "../index.tsx";
import { useRecoilState } from "recoil";

function Projects() {
  const [darkmode, setDarkmode] = useRecoilState(darkmodeState);
  return (
    <div
      className={darkmode ? "text-light" : "text-dark"}
      style={{ backgroundColor: darkmode ? "black" : "white" }}
    >
      <header className="py-5 bg-light general-header text-center">
        <h2>{"Projects"}</h2>
        <p>{"A list of projects I've completed or collaborated in..."}</p>
      </header>
      <Container className="my-5">
        <ProjectCard
          title="Zocialize (In Progress)"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/zocialize.jpg`}
          link="https://www.github.com/donadoio/zocialize"
          text="Social media app with friend system, instant messaging, post text or photos, geotagging. Sort of a hybrid between instagram and twitter."
          tools={[
            <ToolPill tool="react-native" />,
            <ToolPill tool="nest-js" />,
            <ToolPill tool="postgresql" />,
            <ToolPill tool="typescript" />,
          ]}
        />

        <ProjectCard
          title="TomTom AmiGO"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/amigo.jpg`}
          link="https://www.tomtom.com/en_gb/sat-nav/amigo/"
          text="Collaborated in the development of the TomTom AmiGO GPS navigation app available on Android and iOS supporting Android Auto and Carplay."
          tools={[
            <ToolPill tool="react-native" />,
            <ToolPill tool="javascript" />,
            <ToolPill tool="java" />,
            <ToolPill tool="kotlin" />,
            <ToolPill tool="swift" />,
          ]}
        />

        <ProjectCard
          title="Multiplayer Pong"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/pong.jpg`}
          link="https://github.com/axenth/ft_transcendence"
          text="Pong game web-app including friend/chat system, ability to play and watch live pong matches, ranks, OAuth, 2FA."
          tools={[
            <ToolPill tool="nest-js" />,
            <ToolPill tool="react-js" />,
            <ToolPill tool="typescript" />,
            <ToolPill tool="postgresql" />,
            <ToolPill tool="docker" />,
          ]}
        />

        <ProjectCard
          title="cub3d - Raycasting"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/cubed.jpg`}
          link="https://github.com/donadoio/cub3d"
          text='A "3D" maze game using a limited graphics library and raycasting technique, coded in C, inspired by Wolfenstein.'
          tools={[<ToolPill tool="c" />]}
        />

        <ProjectCard
          title="IRC Server"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/happy.jpg`}
          link="https://github.com/Axenth/ft_irc"
          text="Written in C++98, works with IRSSI."
          tools={[<ToolPill tool="cpp" />]}
        />

        <ProjectCard
          title="So Long"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/solong.jpg`}
          link="https://github.com/donadoio/so_long"
          text="A leprechaun theme game using a limited graphics library, coded in C."
          tools={[<ToolPill tool="c" />]}
        />

        <ProjectCard
          title="Minishell"
          img={`${process.env.REACT_APP_FRONTEND_URL}/project-imgs/minishell.jpg`}
          link="https://github.com/donadoio/minishell"
          text="As the name suggests, a minishell written in C with limited library functions, implementing a few built-in commands, searching and forking existing system commands."
          tools={[<ToolPill tool="c" />]}
        />
      </Container>
      <Footer />
    </div>
  );
}

export default Projects;
