import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Footer from './components/Footer.tsx';


function NotFound()
{


  return (
    <>
    <header className='py-5 bg-light general-header text-center'>
        <h2>404</h2>
        <p>Page not found</p>
    </header>
    <div className='content-general'>
      <Container  style={{ maxWidth: '1000px' }}>
        <Row>
          Error: unable to find what you're looking for.
        </Row>
      </Container>
    </div>
    <Footer />
    </>
  );

  
}

export default NotFound;