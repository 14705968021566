import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import getCookie from '../utils/getCookie.ts';
import DashboardNav from './DashboardNav.tsx';


const ProtectedRoutes = function ()
{
    const [authenticated, setAuthenticated] = useState(false);
    const [state, setState] = useState({
        contentToRender: <></>, reRenderSwitch: false,
    });

    useEffect(() => {


        async function tryFetchRefresh() 
        {
            try
            {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${getCookie('donadoJwtRefreshToken')}`,
                    },
                });

                if (res.status === 200)
                {
                    const data = await res.json();
                    console.log('Auth success by refreshing tokens');
                    window.sessionStorage.setItem('donadoJwtAccessToken', data.access_token);
                    document.cookie = `donadoJwtRefreshToken=${data.refresh_token}; path=/`;
                    setAuthenticated(true);
                    if (state.reRenderSwitch === false)
                    {
                        setState({...state, reRenderSwitch: true});
                    }
                    else if (state.reRenderSwitch === true)
                    {
                        setState({...state, reRenderSwitch: false});
                    }
                }
                else
                {
                    console.log(`Unable to refresh tokens, status:  '${res.status}'.`);
                    setState({...state, contentToRender: <Navigate to='/admin/login' />});
                    setAuthenticated(false);
                }
            }
            catch (e)
            {
                console.log('Error when trying to await fetch() or res.json() when refreshing tokens');
                console.log(e);
            }
        }

        async function tryLogin() 
        {
            try
            {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/check`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
                    },
                });

                if (res.status === 200)
                {
                    console.log('Auth success');
                    setState({...state, contentToRender: <><DashboardNav setAuthenticated={setAuthenticated} /><Outlet context={[authenticated, setAuthenticated]}/></>});
                    setAuthenticated(true);
                }
                else if (res.status === 401)
                {
                    console.log('Gotta request new tokens');
                    await tryFetchRefresh();
                }
                else
                {
                    console.log('Unable to login');
                    setState({...state, contentToRender: <Navigate to='/admin/login' />});
                    setAuthenticated(false);
                }
            }
            catch (e)
            {
                console.log('Error when trying to await fetch() or res.json() when logging in');
                console.log(e);
            }
        }

        try
        {
            tryLogin();
        }
        catch (e)
        {
            console.log('Error when trying tryLogin()');
            console.log(e);
        }
    // eslint-disable-next-line
    }, [authenticated]);


    return state.contentToRender;

}

export default ProtectedRoutes;