//import React from 'react';
//import ReactDOM from 'react-dom';
//import * as ReactDOMClient from 'react-dom/client';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals.ts';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  RecoilRoot,
  atom,
} from 'recoil';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';

// Components
import ProtectedRoutes from './components/ProtectedRoutes.tsx'


//Profile
import NavLinks from './components/NavLinks.tsx';
import Profile from './components/Profile.tsx';
import Notes from './components/Notes.tsx';
import Note from './components/Note.tsx';
import Projects from './components/Projects.tsx';
import Login from './components/Login.tsx';
import NotFound from './NotFound.tsx';

// Dashboard
import DashboardProfile from './components/DashboardProfile.tsx';
import DashboardNotes from './components/DashboardNotes.tsx';
import DashboardNote from './components/DashboardNote.tsx';
import DashboardNewNote from './components/DashboardNewNote.tsx';

export const darkmodeState = atom({
  key: 'darkmodeState', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

const root = createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<div className='page-general d-flex flex-column h-100'><NavLinks /><Profile /></div>} />
      <Route path='/projects' element={<div className='page-general d-flex flex-column h-100'><NavLinks /><Projects /></div>} />
      <Route path='/notes/:id' element={<div className='page-general d-flex flex-column h-100'><NavLinks /><Note /></div>} />
      <Route path='/notes' element={<div className='page-general d-flex flex-column h-100'><NavLinks /><Notes /></div>} />
      <Route path='/admin/login' element={<div className='page-general d-flex flex-column h-100'><NavLinks /><Login /></div>} />
      <Route element={<ProtectedRoutes />}>
        <Route path='/admin/dashboard' element={<DashboardProfile />} />
        <Route path='/admin/notes' element={<DashboardNotes />} />
        <Route path="/admin/notes/:id" element={<DashboardNote />} />
        <Route path="/admin/newnote" element={<DashboardNewNote />} />
      </Route>
      <Route path='*' element={<><NavLinks /><NotFound /></>} />
    </Routes>
  </BrowserRouter>
  </RecoilRoot>
);

reportWebVitals();
