import React, {useState, useEffect} from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


function DashboardNotes()
{
  // State variables
  const [state, setState] = useState({
    successMsg: '',
    errorMsg: '',
    notes: <></>,
    showModal: false,
    modal: <></>
  });



  const [authenticated, setAuthenticated] = useOutletContext();


  useEffect(function ()
  {
    const fetchNotes = async function ()
    {
      try
      {
        // Fetch data
        const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/notes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();

        if (!res.ok)
        {
          console.log('Error trying to fetch notes');
          console.log(data);
          setState({...state, successMsg: '', errorMsg: 'Error: could not fetch notes.'});
          return ;
        }
        else if (res.ok)
        {
          setState({...state, errorMsg: '', notes: data.notes.map( element => {
            const noteLink : string = `/admin/notes/${element.id}`;
            return(<Col md={4} className='mx-auto text-center' key={element.id}>
              <Card className='mb-4'>
                <Card.Header as="h5">
                      <Link to={noteLink}>{element.title}</Link>
                      </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {element.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>);
          }).reverse()});
          return ;
        }
      }
      catch(error)
      {
        console.log('Caught error trying to fetch Profile');
        console.log(error);
      }
    }

    fetchNotes();

  }, []);


  return (
    <>
      {state.modal}
      <Container className='bg-white'>
        <Row className='py-5'>
          <p className='text-success'>{state.successMsg}</p>
          <p className='text-danger'>{state.errorMsg}</p>
          <h5 className='mb-4'><Link to='/admin/newnote'>+ Create New Note</Link></h5>
          {state.notes};
        </Row>
      </Container>
    </>
  );

  
}

export default DashboardNotes;
