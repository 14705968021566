import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { darkmodeState } from "../index.tsx";
import { useRecoilState } from "recoil";

function Footer() {
  const [darkmode, setDarkmode] = useRecoilState(darkmodeState);
  return (
    <footer
      className={
        darkmode
          ? "footer-general footer bg-dark mt-auto"
          : "footer-general footer bg-light mt-auto"
      }
    >
      <Container>
        <Row>
          <Col>
            <span className={darkmode ? "text-light" : "text-muted"}>
              ©<script>document.write(new Date().getFullYear())</script>2023{" "}
              <a
                rel="noreferrer"
                href="http://github.com/donadoio/"
                target="_blank"
              >
                donado.io
              </a>
            </span>
          </Col>
          <Col className="text-end">
            <Link
              to="/notes"
              className={darkmode ? "px-2 text-light" : "px-2 text-muted"}
            >
              Notes
            </Link>
            <Link
              to="/admin/login"
              className={darkmode ? "px-2 text-light" : "px-2 text-muted"}
            >
              Admin
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
