import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { ToolPill } from './ToolPill.tsx'
import { darkmodeState } from '../index.tsx';
import {
  useRecoilState,
} from 'recoil';

type props = {
    link: string,
    img: string,
    title: string,
    text: string,
    tools: ToolPill[],
};

function ProjectCard(props)
{
    const [darkmode, setDarkmode] = useRecoilState(darkmodeState);
    const toolsUsed = 
    props.tools.map((element, index)=>{
        return (<div key={index} style={{display:'inline-block'}}>{element}</div>);
    });
  return (
    <Card className='mb-4' bg={darkmode?'dark':'light'}>
    <Row className="no-gutters">
    <Col md={4}>
    <a href={props.link} target='_blank'>
        <Card.Img variant="top" src={props.img} />
    </a>
    </Col>
    <Col md={8}>
    <Card.Body>
      <Card.Title>{props.title}</Card.Title>
      <Card.Text>
        {props.text}
      </Card.Text>
      <div>
      {toolsUsed}
      </div>
    </Card.Body>
    </Col>
    </Row>
  </Card>
  );
}

export default ProjectCard;