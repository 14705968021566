import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import Footer from './Footer.tsx';


function Login()
{
  // State variables
  const [state, setState] = useState({
      errorMsg: '',
      email: '',
      password: '',
  });

  // Set navigate to redirect
  const navigate = useNavigate();

  const tryLogin = async function(context)
  {
    context.preventDefault();

    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: `${context.target[0].value}`,
        password: `${context.target[1].value}`,
      })
    });

    const data = await res.json();
    if (res.ok)
    {
      console.log('login success');
      window.sessionStorage.setItem('donadoJwtAccessToken', data.access_token);
      document.cookie = `donadoJwtRefreshToken=${data.refresh_token}; path=/`;
      navigate('/admin/dashboard');
    }
    else
    {
      console.log('login fail');
      setState({...state, errorMsg: data.message,});
      return ;
    }
  }


  const avatar: string = `${process.env.REACT_APP_API_URL}/srcs/users/headshot.jpg`;
  return (
    <>
    <Row className='pt-5 pb-4 text-center profile-header'>
      <Col>
        <Container style={{maxWidth: '550px'}}>
        <Card className='mb-4'>
          <Card.Header as="h5">
            Admin Login
          </Card.Header>
          <Card.Body>
          <div className='headshot-container'>
        <Image width='100px' height='100px' roundedCircle={true} src={avatar}></Image>
      </div>
            <Form onSubmit={tryLogin}>
              <p className='errorMsg'>{state.errorMsg}</p>
              <Form.Group className="mb-3" controlId="formLogin">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={state.email} onChange={(e) => { setState({...state, email: e.target.value}) }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPass">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter password" value={state.password} onChange={(e) => { setState({...state, password: e.target.value}) }} />
              </Form.Group>
              <Button variant="secondary" type="submit">
                Login
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      </Col>
    </Row>
    <Footer />
    </>
  );
}

export default Login;