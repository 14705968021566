import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { darkmodeState } from '../index.tsx';
import {
  useRecoilState,
} from 'recoil';

function NavLinks()
{
  const [darkmode, setDarkmode] = useRecoilState(darkmodeState);
  return (

    <Navbar expand='lg' variant={darkmode ? 'dark' : 'light'} className={darkmode ? 'nav-general bg-dark' : 'nav-general bg-light'}>
        <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto me-auto">
                  <Nav.Link as={Link} to='/'>Profile</Nav.Link>
                  <Nav.Link as={Link} to='/projects'>Projects</Nav.Link>
                  <Nav.Link eventKey='disabled' disabled>Blog</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default NavLinks;