import React, {useState} from 'react';
import MDEditor from "@uiw/react-md-editor";
import { useNavigate, useOutletContext } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function DashboardNewNote()
{
  // State variables
  const [state, setState] = useState({
    successMsg: '',
    errorMsg: '',
    title: '',
    description: '',
    content: '',
  });


  // Set navigate for redirecting
  let navigate = useNavigate();


  const [authenticated, setAuthenticated] = useOutletContext();


  const tryCreateNote = async function (context)
  {
    context.preventDefault();
    console.log(context.target);
    try
    {
      let noteDto = {
        title: `${state.title}`,
        description: `${state.description}`,
        content: `${state.content}`,
      };
      console.log(noteDto);
      let res = await fetch(`${process.env.REACT_APP_API_URL}/update/newnote`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteDto),
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          setAuthenticated(false);
          setTimeout(function () {
            tryCreateNote(context);
            }, 2000);
          return ;
        }
        else
        {
          const data = await res.json();
          setState({...state, errorMsg: `Error: ${data.message}`, successMsg: ''});
          return ;
        }
      }
      else
      {
        navigate('/admin/notes');
      }
    }
    catch(e)
    {
      console.log('Caught error trying to submitProfile');
      console.log(e);
    }
  }

  return (
      <Container className='bg-white'>
        <Row className='py-5'>
          <Form onSubmit={tryCreateNote}>
              <Row className='mt-5'>
                <h1 className='text-center'> Create New Note </h1>
              <p className='text-success'>{state.successMsg}</p>
              <p className='text-danger'>{state.errorMsg}</p>
                <Form.Label column lg={2}>
                  Title
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Title" value={state.title ? state.title : ''} onChange={(e) => { setState({...state, title: e.target.value}) }} />
                </Col>
              </Row>

              <Row className='mt-5'>
                <Form.Label column lg={2}>
                  Description
                </Form.Label>
                <Col>
                <Form.Control as='textarea' rows={2} value={state.description ? state.description : ''} onChange={(e) => { setState({...state, description: e.target.value}) }}/>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Form.Label column lg={2}>
                  Content
                </Form.Label>
                <Col>
                <Form.Control style={{display: 'inline-table'}} as={MDEditor} height='500px' value={state.content ? state.content : ''} onChange={(e) => { setState({...state, content: e}) }}/>
                </Col>
              </Row>

              <Button type='submit' className='mx-1 my-3'>Create</Button>
            </Form>

          {state.note}
        </Row>
      </Container>
  );

  
}

export default DashboardNewNote;
