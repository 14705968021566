import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';


function DashboardNav( props ) {
  const [state, setState] = useState({
    errMsg: '',
    pwErrMsg: '',
    pwSuccessMsg: '',
    showModal: false,
    oldPassword: '',
    newPassword: '',
    reNewPassword: '',
  });

  // Set navigate for redirecting
  let navigate = useNavigate();

  const tryLogOut = async function(context)
  {
    context.preventDefault();
    try
    {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
        },
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          props.setAuthenticated(false);
          setTimeout(function () {
            tryLogOut(context);
            }, 2000);
          return ;
        }
        else
        {
          setState({...state, errMsg: 'Error: Unable to log out!'});
          return ;
        }
      }
      else
      {
        window.sessionStorage.setItem('donadoJwtAccessToken', '');
        navigate('/admin/login');
        return ;
      }
    }
    catch(e)
    {
      console.log('Caught error trying to log out');
      console.log(e);
      setState({...state, errMsg: 'Error: Unable to log out!'});
    }
    return ;
  }

  const handleClose = () => setState({...state, showModal: false,});
  const handleShow = () => setState({...state, showModal: true,});

  const tryChangePw = async function(context)
  {
    context.preventDefault();
    if (context.target[1].value !== context.target[2].value)
    {
      setState({...state, pwSuccessMsg: '', pwErrMsg: 'Error: Passwords do not match.'});
      return ;
    }
    try
    {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/changepassword`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
        },
        body: JSON.stringify({
          oldPassword: context.target[0].value,
          newPassword: context.target[1].value
      }),
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          props.setAuthenticated(false);
          setTimeout(function () {
            tryChangePw(context);
            }, 2000);
          return ;
        }
        else
        {
          setState({...state, pwSuccessMsg: '', pwErrMsg: 'Error: unable to change password, try again carefully.'});
        }
      }
      else
      {
        setState({...state, pwErrMsg: '', oldPassword: '', newPassword: '', reNewPassword: '', pwSuccessMsg: 'Success: password changed.'});
      }
    }
    catch(e)
    {
      console.log('Caught error trying to change password');
      console.log(e);
      setState({...state, pwSuccessMsg: '', pwErrMsg: 'Error: unable to change password.'});
    }
  }

  const openPwModal = function (context)
  {
    context.preventDefault();
    handleShow();
  }


  return (
    <>
    <Modal
          show={state.showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='text-danger'>{state.pwErrMsg}</p>
            <p className='text-success'>{state.pwSuccessMsg}</p>
            <Form onSubmit={tryChangePw}>
              <Form.Group className="mb-3" controlId="formPass">
                <Form.Label>Old password</Form.Label>
                <Form.Control type="password" placeholder="Enter old password" value={state.oldPassword} onChange={(e) => { setState({...state, oldPassword: e.target.value}) }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formNewPass">
                <Form.Label>New password</Form.Label>
                <Form.Control type="password" placeholder="Enter new password" value={state.newPassword} onChange={(e) => { setState({...state, newPassword: e.target.value}) }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formReNewPass">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control type="password" placeholder="Enter new password again" value={state.reNewPassword} onChange={(e) => { setState({...state, reNewPassword: e.target.value}) }} />
              </Form.Group>
              <Modal.Footer>
                <Button type='submit' variant="primary">Change Password</Button>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
      </Modal>

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
      <Navbar.Brand>[donado.io] Dashboard</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link as={Link} to='/admin/dashboard'>Profile</Nav.Link>
            <Nav.Link as={Link} to='/admin/notes'>Notes</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Item className='text-danger'>{state.errMsg}</Nav.Item>
            <NavDropdown title="Options" id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={openPwModal}>Change Password</NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/' target='_blank'>View Profile</NavDropdown.Item>
                <NavDropdown.Divider />
            <NavDropdown.Item onClick={tryLogOut}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default DashboardNav;