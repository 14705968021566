import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Footer from './Footer.tsx';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism';

function Note()
{
  // State variables
  const [state, setState] = useState({
    successMsg: '',
    errorMsg: '',
    noteTitle: '',
    noteDescription: '',
    noteContent: '',
    noteId: '',
  });

  let noteId = useParams();


  // fetch data logic from home module of server
  useEffect(function ()
  {
    const fetchNote = async function ()
    {
      try
      {
        // Fetch data
        const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/notes/${noteId.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();

        if (!res.ok)
        {
          console.log('Error trying to fetch note');
          console.log(data);
          setState({...state, successMsg: '', errorMsg: 'Error: could not fetch note.'});
          return ;
        }
        else if (res.ok)
        {
          setState({...state, errorMsg: '', noteId: noteId.id, noteTitle: data.note.title, noteDescription: data.note.description, noteContent: data.note.content});
          return ;
        }
      }
      catch(error)
      {
        console.log('Caught error trying to fetch Note');
        console.log(error);
      }
    }

      fetchNote();

  }, []);


  return (
    <>
    <header className='py-5 bg-light general-header text-center'>
        <h2>{ state.noteTitle ? state.noteTitle : 'Loading...' }</h2>
        <p>{ state.noteDescription ? state.noteDescription : 'Loading...' }</p>
    </header>
    <div className='content-general'>
      <Container  style={{ maxWidth: '1000px' }}>
        <Row>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{
      code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={dark}
            language={match[1]}
            PreTag="div"
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }
    }}>
            {state.noteContent}
          </ReactMarkdown>
        </Row>
      </Container>
    </div>
    <Footer />
    </>
  );

  
}

export default Note;