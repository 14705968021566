import React from 'react';
import Image from 'react-bootstrap/Image';
import { darkmodeState } from '../index.tsx';
import {
  useRecoilState,
} from 'recoil';

type props = {
    tool: string,
};


function ToolPill(props)
{
    const [darkmode, setDarkmode] = useRecoilState(darkmodeState);
    let component;
    switch (props.tool) {
        case 'react-native':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/react.svg`} height='18px'/> React Native</>
            break;
        case 'react-js':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/react.svg`} height='18px'/> React JS</>
            break;
        case 'swing':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/gem.svg`} height='18px'/> Swing</>
            break;
        case 'nest-js':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/nestjs.svg`} height='18px'/> Nest JS</>
            break;
        case 'django':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/django.svg`} height='18px'/> Django</>
            break;
        case 'spring-boot':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/spring.svg`} height='18px'/> Spring Boot</>
            break;
        case 'c':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/c.svg`} height='18px'/> C</>
            break;
        case 'cpp':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/cpp.svg`} height='18px'/> C++</>
            break;
        case 'javascript':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/js.svg`} height='18px'/> Javascript</>
            break;
        case 'java':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/java.svg`} height='18px'/> Java</>
            break;
        case 'python':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/python.svg`} height='18px'/> Python</>
            break;
        case 'kotlin':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/kotlin.svg`} height='18px'/> Kotlin</>
            break;
        case 'swift':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/swift.svg`} height='18px'/> Swift</>
            break;
        case 'docker':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/docker.svg`} height='18px'/> Docker</>
            break;
        case 'hibernate':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/gem.svg`} height='18px'/> Hibernate</>
            break;
        case 'postgresql':
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/psql.svg`} height='18px'/> PostgreSQL</>
            break;
        case 'typescript':
                component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/typescript.svg`} height='18px'/> Typescript</>
                break;
        default:
            component = <><Image src={`${process.env.REACT_APP_API_URL}/srcs/icons/gem.svg`} height='18px'/> Other</>
            break;
    }
  return (
    <div className={darkmode?'bg-dark text-light p-2 rounded-pill border border-secondary m-1':'bg-light text-dark p-2 rounded-pill border border-dark m-1'} style={{minWidth: 66, display: 'inline-block'}}>
        {component}
      </div>
  );
}

export default ToolPill;