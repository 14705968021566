import React, {useState, useEffect} from 'react';
import MDEditor from "@uiw/react-md-editor";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


function DashboardNote()
{
  // State variables
  const [state, setState] = useState({
    successMsg: '',
    errorMsg: '',
    id: '',
    title: '',
    description: '',
    content: '',
    showModal: false,
  });


  // Set navigate for redirecting
  let navigate = useNavigate();


  const [authenticated, setAuthenticated] = useOutletContext();
  let noteId = useParams();


  useEffect(function ()
  {
    const fetchNote = async function ()
    {
      try
      {
        // Fetch data
        console.log(noteId);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/notes/${noteId.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();

        if (!res.ok)
        {
          console.log('Error trying to fetch notes');
          console.log(data);
          setState({...state, successMsg: '', errorMsg: 'Error: could not fetch note.'});
          return ;
        }
        else if (res.ok)
        { 
          console.log(data);
          setState({...state, errorMsg: '', id: data.note.id, title: data.note.title, description: data.note.description, content: data.note.content, });
          return ;
        }
      }
      catch(error)
      {
        console.log('Caught error trying to fetch Profile');
        console.log(error);
      }
    }

    fetchNote();

  }, []);

  const tryUpdateNote = async function (context)
  {
    context.preventDefault();
    console.log(context.target);
    try
    {
      let noteDto = {
        id: parseInt(noteId.id),
        title: `${state.title}`,
        description: `${state.description}`,
        content: `${state.content}`,
      };
      console.log(noteDto);
      let res = await fetch(`${process.env.REACT_APP_API_URL}/update/note`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteDto),
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          setAuthenticated(false);
          setTimeout(function () {
            tryUpdateNote(context);
            }, 2000);
          return ;
        }
        else
        {
          const data = await res.json();
          setState({...state, errorMsg: `Error: ${data.message}`, successMsg: ''});
          return ;
        }
      }
      else
      {
        setState({...state, errorMsg: '', successMsg: `Success: Note updated`});
      }
    }
    catch(e)
    {
      console.log('Caught error trying to updateNote');
      console.log(e);
    }
  }


  const handleClose = () => setState({...state, showModal: false,});
  const handleShow = () => setState({...state, showModal: true,});

  const tryDeleteNote = async function (context)
  {
    context.preventDefault();
    try
    {
      let noteDto = {
        id: parseInt(noteId.id),
      };
      console.log(noteDto);
      let res = await fetch(`${process.env.REACT_APP_API_URL}/update/deletenote`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${window.sessionStorage.getItem('donadoJwtAccessToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteDto),
      });
      if (!res.ok)
      {
        if (res.status === 401)
        {
          setAuthenticated(false);
          setTimeout(function () {
            tryDeleteNote(context);
            }, 2000);
          return ;
        }
        else
        {
          const data = await res.json();
          setState({...state, errorMsg: `Error: ${data.message}`, successMsg: ''});
          return ;
        }
      }
      else
      {
        navigate('/admin/notes');
      }
    }
    catch(e)
    {
      console.log('Caught error trying to updateNote');
      console.log(e);
    }
  }

  const openDeleteModal = async function (context)
  {
    context.preventDefault();
    handleShow();
  }

  return (
      <>
        <Modal
            show={state.showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='text-danger'>{state.pwErrMsg}</p>
              <p className='text-success'>{state.pwSuccessMsg}</p>
              <p>
                Are you sure you want to delete {state.title}[{state.id}]?
              </p>
              <Form onSubmit={tryDeleteNote}>
                <Modal.Footer>
                  <Button type='submit' variant="danger">Delete</Button>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
        </Modal>
        <Container className='bg-white'>
          <Row className='py-5'>
            <Form onSubmit={tryUpdateNote}>
                <Row className='mt-5'>
                  <h1 className='text-center'> Note Editor </h1>
                <p className='text-success'>{state.successMsg}</p>
                <p className='text-danger'>{state.errorMsg}</p>
                  <Form.Label column lg={2}>
                    Title
                  </Form.Label>
                  <Col>
                    <Form.Control type="text" placeholder="Title" value={state.title ? state.title : ''} onChange={(e) => { setState({...state, title: e.target.value}) }} />
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Form.Label column lg={2}>
                    Description
                  </Form.Label>
                  <Col>
                  <Form.Control as='textarea' rows={2} value={state.description ? state.description : ''} onChange={(e) => { setState({...state, description: e.target.value}) }}/>
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Form.Label column lg={2}>
                    Content
                  </Form.Label>
                  <Col>
                  <Form.Control style={{display: 'inline-table'}} as={MDEditor} height='500px' value={state.content ? state.content : ''} onChange={(e) => { setState({...state, content: e}) }}/>
                  </Col>
                </Row>

                <p>
                  <Button type='submit' className='mx-1 my-3'>Update Changes</Button>
                </p>
                <p>
                  <Button onClick={openDeleteModal} variant='danger' className='mx-1 my-3'>Delete</Button>
                </p>
              </Form>

            {state.note}
          </Row>
        </Container>
      </>
  );

  
}

export default DashboardNote;
