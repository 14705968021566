import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Footer from './Footer.tsx';

function Notes()
{
  // State variables
  const [state, setState] = useState({
    successMsg: '',
    errorMsg: '',
    notes: <></>,
  });

  // fetch data logic from home module of server
  useEffect(function ()
  {
    const fetchNotes = async function ()
    {
      try
      {
        // Fetch data
        const res = await fetch(`${process.env.REACT_APP_API_URL}/resource/notes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await res.json();

        if (!res.ok)
        {
          console.log('Error trying to fetch notes');
          console.log(data);
          setState({...state, successMsg: '', errorMsg: 'Error: could not fetch notes.'});
          return ;
        }
        else if (res.ok)
        {
          setState({...state, errorMsg: '', notes: data.notes.map( element => {
            const noteLink : string = `/notes/${element.id}`;
            return(<Col md={4} key={element.id} className='mx-auto text-center'>
              <Card className='mb-4'>
                <Card.Header as="h5">
                <Link style={{ TextDecoration: 'none' }} to={noteLink}>{element.title}</Link>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {element.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>);
          }).reverse()});
          return ;
        }
      }
      catch(error)
      {
        console.log('Caught error trying to fetch Profile');
        console.log(error);
      }
    }

      fetchNotes();

  }, []);

  return (
    <>
    <header className='py-5 bg-light general-header text-center'>
        <h2>Notes</h2>
        <p>Browse available notes and documentation...</p>
    </header>
    <div className='content-general'>
      <Container  style={{ maxWidth: '1000px' }}>
        <Row>
          {state.notes}
        </Row>
      </Container>
    </div>
    <Footer />
    </>
  );

  
}

export default Notes;